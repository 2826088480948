import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../components/seo";
import SignUp from "../components/signup";
import "./index.scss";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query BlogPostIndex {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              slug
            }
          }
        }
      }
    }
  `);

  const index = data.allMarkdownRemark.edges.map((edge) => {
    return (
      <div key={edge.node.frontmatter.slug} className="card article">
        <div className="card-content">
          <div className="media">
            <div className="media-content has-text-centered">
              <Link to={edge.node.frontmatter.slug}>
                <p className="title article-title">
                  {edge.node.frontmatter.title}
                </p>
                <p className="subtitle article-subtitle">
                  {edge.node.frontmatter.date}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <Layout>
      <SEO title="Home" />
      <section className="hero is-medium is-bold">
        <div className="hero-body">
          <div className="container has-text-centered">
            <Link to={"/"}>
              <h1 className="title">Toast Avocado</h1>
            </Link>
          </div>
          <div className="column is-4 is-offset-4 has-margin-top-2rem">
            <SignUp />
          </div>
        </div>
      </section>

      <div className="container">
        <section className="articles">
          <div className="column is-8 is-offset-2">{index}</div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
